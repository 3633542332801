import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import '../styles/Contactenos.css';
import '../styles/General.css';
import Modal from './Modal';

const Contactenos = () => {
  const form = useRef();
  const [showModal, setShowModal] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const emailData = {
      to_email: form.current.email.value,
      message: form.current.message.value,
    };

    // Send via EmailJS
    emailjs
      .send('service_ksrkc0u', 'template_s140enp', emailData, 'o33XTlNZkxdv7mGJy')
      .then(
        () => {
          console.log('EmailJS SUCCESS!');
          setShowModal(true); // Show the modal on success
        },
        (error) => {
          console.log('EmailJS FAILED...', error.text);
        }
      );

    // Manually send form data to Formspree
    const formData = new FormData(form.current);
    fetch('https://formspree.io/f/xjkvbvna', {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log('Formspree SUCCESS!');
          setShowModal(true); // Show the modal on success
          form.current.reset(); // Clear form fields after successful submission
        } else {
          console.error('Formspree FAILED');
        }
      })
      .catch((error) => {
        console.error('Formspree ERROR...', error);
      });
  };

  return (
    <div className="div-bgcont">
      <h2 className="title">Contacto</h2>

      <form ref={form} className="contact-form" onSubmit={sendEmail}>
        <div className="form-group">
          <label htmlFor="email" className="form-label">E-mail</label>
          <input type="email" name="to_email" id="email" className="form-input" placeholder="" required />
        </div>

        <div className="form-group">
          <label htmlFor="serviceDetails" className="form-label">Indique o tipo de serviço que procura:</label>
          <textarea name="message" id="serviceDetails" className="form-textarea" placeholder="" required></textarea>
        </div>

        <button type="submit" className="submit-button">Contacte-nos</button>
      </form>

      {/* Modal Component */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        message="Agradecemos o seu contacto com a Paradox Studios. Estamos a analisar o seu pedido e entraremos em contacto consigo assim que possível.Até já!"
      />
    </div>
  );
};

export default Contactenos;

