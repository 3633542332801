import React from 'react';
import '../styles/Modal.css';

const Modal = ({ show, onClose, message }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <button onClick={onClose} className="close-button">OK</button>
      </div>
    </div>
  );
};

export default Modal;
