import React from 'react';
import { ReactComponent as HeroImg } from '../images/Hero.svg'; // Import the SVG as a React component

import '../styles/HeroStyle.css'; // Custom styling

const Hero = () => {
    return (
        <div className="hero-section">
            <HeroImg /> {/* Render the SVG as a component */}
        </div>
    );
};

export default Hero;